/* @import url("https://fonts.googleapis.com/css2?family=Bellefair&family=Inter:wght@600&family=Noto+Sans+Thai:wght@100..900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai+Looped:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* Base styles for all screens */
.App {
  display: flex;
  flex-direction: row;
}

@import url("https://fonts.googleapis.com/css?family=Sarabun");

@font-face {
  font-family: "CSChatThaiUI";
  src: local("CSChatThaiUI"), url(./fonts/CSChatThaiUI.ttf) format("opentype");
}

body {
  font-family: "CSChatThaiUI", sans-serif !important;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-family: "CSChatThaiUI", sans-serif !important;
}

span,
p,
label {
  font-family: "CSChatThaiUI", sans-serif !important;
}

.ms-Nav-chevronButton {
  font-family: "CSChatThaiUI", sans-serif !important;
  font-size: 22px;
}

.ms-Nav-linkText {
  font-family: "CSChatThaiUI", sans-serif !important;
  font-size: 17px;
}

.ms-DetailsRow-cell {
  font-family: "CSChatThaiUI", sans-serif !important;
  font-size: 14px;
}

.ms-ContextualMenu-itemText {
  font-family: "CSChatThaiUI", sans-serif !important;
  font-size: 18px;
}

.ms-Pivot-text {
  font-family: "CSChatThaiUI", sans-serif !important;
  font-size: 16px;
}

.ms-DetailsHeader-cellName {
  font-family: "CSChatThaiUI", sans-serif !important;
  font-size: 15px;
  font-weight: 600;
}

.ms-DocumentCardTitle {
  font-family: "CSChatThaiUI", sans-serif !important;
}

.ms-Nav {
  background-color: white;
  border: none;
  font-family: "Sarabun", sans-serif !important;
}

.ms-Grid-col {
  padding: 0;
  margin: 0;
  border: none;
}

.ms-Grid-row {
  border: none;
}

.ms-Grid {
  padding: 0;
  margin: 0;
  background-color: white;
  border: none;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.responsive-element {
  box-sizing: border-box;
  background-color: var(--background-color);
  width: 100%;
  overflow-y: auto; /* Ensure vertical scrolling */
  height: 100vh; /* Set height to full viewport height */
  min-height: 100vh;
}

.responsive-element-summary {
  box-sizing: border-box;
  background-color: var(--background-color);
  width: 100%;
  overflow-y: auto; /* Ensure vertical scrolling */
  min-height: 100vh;
}

.header-topic {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* To push the two child elements to the opposite sides */
  align-items: right;
  position: relative;
}

.header-part {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 50px;
  margin-right: 50px;
  position: relative;
}

.header-topic-word {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: flex-start;
}

.header-topic-switch {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-left: 20px;
  position: absolute;
  right: -35px;
  bottom: 30px;
  color: var(--primary-color);
}

.header-close-form-switch {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-left: 20px;
  position: absolute;
  right: -30px;
  bottom: 80px;
  color: var(--primary-color);
}

.dialogContentCustomScroll {
  overflow-y: auto !important;
}

.dialogContentCustomScroll::-webkit-scrollbar {
  width: 6px !important;
}

.dialogContentCustomScroll::-webkit-scrollbar-track {
  background: #f0f0f0 !important;
  border-radius: 10px !important;
}

.dialogContentCustomScroll::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

.dialogContentCustomScroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}


@media only screen and (min-width: 332px) and (max-width: 560px) {
  .header-topic {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* To push the two child elements to the opposite sides */
    align-items: right;
    position: relative;
  }

.header-topic-switch {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-left: 20px;
    position: absolute;
    right: -30px;
    bottom: 315px;
    color: var(--primary-color);
  }

.switch-language-postion {
    display: flex;
    flex-direction: row-reverse;
    width: 115%;
   
}

.header-wrong-url-switch {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-left: 20px;
    position: absolute;
    right: -120px;
    bottom: 0px;
    color: var(--primary-color);
  }
}

@media only screen and (min-width: 560px) and (max-width: 767px) {
.header-topic {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* To push the two child elements to the opposite sides */
    align-items: right;
    position: relative;
}

.header-topic-switch {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-left: 20px;
    position: absolute;
    right: 0px;
    bottom: 270px;
    color: var(--primary-color);
}

.switch-language-postion {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

.header-wrong-url-switch {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-left: 20px;
    position: absolute;
    right: -220px;
    bottom: 0px;
    color: var(--primary-color);
  }
}

.header-text-switch {
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: "green";
}

.font-header {
  font-size: 20px;
}

.company-information {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  column-gap: 10px;
}

.main-background {
  width: 100%;
  margin: 0 auto;
  background-color: #faf9f8;
}

.field-long {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.field-long-summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.field-long-topic-summary {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.field-long-topic-summary-email {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.branch-display {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.branchNumber {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.build-number-and-address {
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
}

.street {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.province {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.district {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.subdistrict {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.postCode {
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
}

.contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
}

.card-style {
  padding: 10px;
  background-color: #faf9f8;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 5px;
  padding-bottom: 20px;
}

.card-style-summary {
  padding: 10px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 25px;
  border-radius: 5px;
  background-color:#ffffff;
}

.card-style-display-text {
  padding: 10px;
  width: 80%;
  background-color: #ffffff;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 25px;
  border-radius: 5px;
}

.container {
  padding: 10px;
}

.label-and-data {
  display: flex;
  align-items: left;
}

.label-of-summary-data {
  width: 30%;
}

.fluent-ui-dialog {
  width: 18rem;
  height: 20rem;
}

.dialog-body {
  display: flex;
  position: relative;
  height: auto;
  width: 15rem;
  height: 8rem;
}

.fluent-direction {
  display: flex;
  flex-direction: row;
  width: 10rem;
  position: absolute;
  left: 0.8rem;
  bottom: 0.15rem;
}

.button-trigger {
  display: flex;
  flex-direction: row;
  margin-right: 1.25rem;
}

.button-dialog {
  display: flex;
  width: 1.25rem;
}

.footer {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa; 
  padding: 2px 0; 
  text-align: center;
  z-index: 9999;
}

.powered-by {
  display: flex;
  font-size: 14px; 
  color: #6c757d; 
  align-items: center;
  justify-items: center;
 
}

.software-name {
  font-weight: bold; 
}



@media only screen and (min-width: 768px) {
.App {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.fluent-ui-dialog {
    width: 30rem;
    height: 20rem;
  }

.dialog-body {
    display: flex;
    position: relative;
    height: auto;
    width: 27rem;
    height: 6rem;
  }

.fluent-direction {
    display: flex;
    flex-direction: row;
    width: 10rem;
    position: absolute;
    left: 13rem;
    bottom: 0.15rem;
  }

.button-trigger {
    display: flex;
    flex-direction: row;
    margin-right: 1.25rem;
  }
.button-dialog {
    display: flex;
    width: 1.25rem;
  }

.container {
    padding: 20px;
  }

.responsive-element {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.responsive-element-summary {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.card-style {
    padding: 20px;
    background-color: #faf9f8;
    margin: 20px 0;
    width: 600px;
    border-radius: 5px;
    padding-bottom: 20px;
  }

.card-style-summary {
    padding: 20px;
    background-color: #faf9f8;
    margin: 20px 0;
    width: 600px;
    border-radius: 5px;
    padding-bottom: 20px;
}


.header-topic {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* To push the two child elements to the opposite sides */
    align-items: center;
  }

.header-part {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-left: 10%;
    margin-right: 10%;
    width: 100%;
    border-radius: 10px;
}

.header-topic-word {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    width: 100%;
    height: 50px;
  }

.header-topic-switch {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-left: 20px;
    position: absolute;
    right: -1px;
    bottom: 270px;
    color: var(--primary-color);
}

.header-close-form-word {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    width: 50%;
    height: 50px;
    position: relative;
    background-color: red;
  }

.header-close-form-switch {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-left: 20px;
    position: absolute;
    right: -120px;
    bottom: 0px;
    color: var(--primary-color);
}

.footer {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f8f9fa; 
    padding: 2px 0; 
    text-align: center;
    z-index: 9999;
}

.powered-by {
    display: flex;
    font-size: 14px; 
    color: #6c757d; 
    align-items: center;
    justify-items: center;
}

form {
    display: flex;
    flex-direction: column;
    margin-top: 0em;
    align-items: center;
  }

.label-of-summary-data {
    font-size: 1.1rem;
}

.card-style-display-text {
    padding: 10px;
    width: 50%;
    background-color: #ffffff;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 25px;
    border-radius: 5px;
}

.switch-language-postion {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

.header-wrong-url-switch {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-left: 20px;
    position: absolute;
    right: -220px;
    bottom: 0px;
    color: var(--primary-color);
}

.card-style-successful {
    padding: 20px;
    margin: 20px 0;
    width: 600px;
    border-radius: 5px;
    padding-bottom: 20px;
  }
}